import { useState, useEffect } from "react";
import {
  FaUser,
  FaTasks,
  FaChartBar,
  FaDoorClosed,
  FaAngleDown,
} from "react-icons/fa";
import { MdOutlinePayments } from "react-icons/md";
import { TbReport, TbSettings } from "react-icons/tb";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate and useLocation

const SidebarItem = ({
  icon: Icon,
  title,
  isMenuOpen,
  toggleMenu,
  subItems,
  isActive,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const routes = {
    "Manage Employees": "/manageemployees",
    "Update Attendance": "/updateattendance",
    "View Attendance": "/viewattendance",
    "View Leave Requests": "/viewleaverequests",
    "Leave Policies": "/leavePolicies",
    "View Payroll": "/viewpayroll",
    "Generate Payslips": "/generatepayslips",
    "Manage Bonuses": "/managebonuses",
    "Employee Reports": "/employeereports",
    "Attendance Reports": "/attendancereports",
    "Leave Reports": "/leavereports",
    "Payroll Reports": "/payrollreports",
    "Attendance Settings": "/attendancesettings",
    "Leave Settings": "/leavesettings",
    "Payroll Settings": "/payrollsettings",
  };

  const handleSubItemClick = (subItem) => {
    const route = routes[subItem];
    if (route) {
      navigate(route);
    }
  };

  const isSubItemActive =
    subItems &&
    subItems.some((subItem) => routes[subItem] === location.pathname);

  return (
    <>
      <li
        className={`p-4 hover:bg-slate-700 flex justify-between items-center cursor-pointer ${
          isActive || isSubItemActive ? "bg-slate-700 " : ""
        }`}
        onClick={toggleMenu}
      >
        <div>
          <Icon className="inline mr-2" /> {title}
        </div>
        {subItems && (
          <FaAngleDown
            className={`transform ${
              isMenuOpen ? "rotate-180" : ""
            } transition-transform duration-300`}
          />
        )}
      </li>
      {isMenuOpen && subItems && (
        <ul className="ml-8 mt-2 transition-all duration-300 ease-in-out">
          {subItems.map((subItem, index) => (
            <li
              key={index}
              className={`p-2 hover:bg-slate-700 cursor-pointer ${
                routes[subItem] === location.pathname ? "bg-slate-700 " : ""
              }`}
              onClick={() => handleSubItemClick(subItem)}
            >
              {subItem}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  // Determine which menu should be open based on the current route
  useEffect(() => {
    if (
      location.pathname.includes("/updateattendance") ||
      location.pathname.includes("/viewattendance")
    ) {
      setOpenMenu("attendance");
    } else if (
      location.pathname.includes("/viewleaverequests") ||
      location.pathname.includes("/leavePolicies")
    ) {
      setOpenMenu("leave");
    } else if (
      location.pathname.includes("/viewpayroll") ||
      location.pathname.includes("/generatepayslips") ||
      location.pathname.includes("/managebonuses")
    ) {
      setOpenMenu("payroll");
    } else if (
      location.pathname.includes("/employeereports") ||
      location.pathname.includes("/attendancereports") ||
      location.pathname.includes("/leavereports") ||
      location.pathname.includes("/payrollreports")
    ) {
      setOpenMenu("reports");
    } else if (
      location.pathname.includes("/attendancesettings") ||
      location.pathname.includes("/leavesettings") ||
      location.pathname.includes("/payrollsettings")
    ) {
      setOpenMenu("settings");
    } else {
      setOpenMenu(null); // Reset if no matching route
    }
  }, [location.pathname]);

  const handleDashboardClick = () => {
    navigate("/dashboard");
  };

  const handleEmployeeClick = () => {
    navigate("/manageemployees");
  };

  const isDashboardActive = location.pathname === "/dashboard";
  const isEmployeeActive = location.pathname === "/manageemployees";

  const toggleMenu = (menu) => {
    setOpenMenu((prevMenu) => (prevMenu === menu ? null : menu));
  };

  return (
    <>
      <div className="bg-primary text-white p-4 md:hidden">
        <button onClick={() => setIsOpen(!isOpen)}>
          <FaChartBar />
        </button>
      </div>

      <div
        className={`${
          isOpen ? "block" : "hidden"
        } md:block w-64 bg-slate-800 text-green-50 h-auto fixed md:relative z-10 transition-transform duration-300 ease-in-out`}
      >
        <div className="p-4 text-lg font-bold">
          <img src="/asset/logo.png" alt="Logo" className="h-12" />
        </div>
        <ul className="mt-6 p-2">
          <li
            className={`p-4 hover:bg-slate-700 ${
              isDashboardActive ? "bg-slate-700 " : ""
            }`}
            onClick={handleDashboardClick}
          >
            <FaChartBar className="inline mr-2" /> Dashboard
          </li>

          <li
            className={`p-4 hover:bg-slate-700 ${
              isEmployeeActive ? "bg-slate-700 " : ""
            }`}
            onClick={handleEmployeeClick}
          >
            <FaUser className="inline mr-2" /> Employees
          </li>

          <SidebarItem
            icon={FaTasks}
            title="Attendance"
            isMenuOpen={openMenu === "attendance"}
            toggleMenu={() => toggleMenu("attendance")}
            subItems={["View Attendance", "Update Attendance"]}
            isActive={location.pathname.includes("/attendance")}
          />
          <SidebarItem
            icon={FaDoorClosed}
            title="Leave"
            isMenuOpen={openMenu === "leave"}
            toggleMenu={() => toggleMenu("leave")}
            subItems={["View Leave Requests", "Leave Policies"]}
            isActive={location.pathname.includes("/leave")}
          />
          <SidebarItem
            icon={MdOutlinePayments}
            title="Payroll"
            isMenuOpen={openMenu === "payroll"}
            toggleMenu={() => toggleMenu("payroll")}
            subItems={["View Payroll", "Generate Payslips", "Manage Bonuses"]}
            isActive={location.pathname.includes("/payroll")}
          />
          <SidebarItem
            icon={TbReport}
            title="Reports"
            isMenuOpen={openMenu === "reports"}
            toggleMenu={() => toggleMenu("reports")}
            subItems={[
              "Employee Reports",
              "Attendance Reports",
              "Leave Reports",
              "Payroll Reports",
            ]}
            isActive={location.pathname.includes("/report")}
          />
          <SidebarItem
            icon={TbSettings}
            title="Settings"
            isMenuOpen={openMenu === "settings"}
            toggleMenu={() => toggleMenu("settings")}
            subItems={[
              "Attendance Settings",
              "Leave Settings",
              "Payroll Settings",
            ]}
            isActive={location.pathname.includes("/settings")}
          />
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
