import "./App.css";
import Login from "./Pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard"; // Import your Dashboard component
import ManageEmployees from "./Pages/employees/manage_employee";
import ViewAttendance from "./Pages/attendance/view_attendance";
import UpdateAttendance from "./Pages/attendance/update_attendance";
import ViewLeaverequests from "./Pages/leave/view_leave_requests";
import LeavePolicies from "./Pages/leave/leave_policies";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/manageemployees" element={<ManageEmployees />} />
        <Route path="/viewattendance" element={<ViewAttendance />} />
        <Route path="/updateattendance" element={<UpdateAttendance />} />
        <Route path="/viewleaverequests" element={<ViewLeaverequests />} />
        <Route path="/leavePolicies" element={<LeavePolicies />} />
      </Routes>
    </Router>
  );
}

export default App;
