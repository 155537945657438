import React, { useState, useRef } from "react";
import BasicDetails from "./Besic_details";
import EmploymentDetails from "./Employment_details";
import EmployeePayrollDetails from "./Employee_payroll_details";
import { AppEmployeeApi } from "../ApiBaseUrl";
import { AddEmployeePayroll } from "../ApiBaseUrl";
import { IoClose } from 'react-icons/io5';

const AddEmployee = ({ close }) => { // Receive `close` as a prop
  const [step, setStep] = useState(1);
  const [errorModal, setErrorModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState();
  const [snackbarVisible, setSnackbarVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    basicDetails: {},
    employmentDetails: {},
    payrollDetails: {},
  });
  const formRef = useRef(null);

  // Close button to trigger the `close` function
  const closeForm = () => {
    close();
  };

  const nextStep = async (e) => {
    e.preventDefault();
    if (formRef.current.checkValidity()) {
      if (step === 2) {
        const combinedData = {
          ...formData.basicDetails,
          ...formData.employmentDetails,
        };
        await handleEmployeeRegisterApi(combinedData);
      } else {
        setStep(step + 1);
      }
    } else {
      formRef.current.reportValidity();
    }
  };

  const handleFormDataChange = (step, data) => {
    setFormData((prevData) => ({
      ...prevData,
      [step]: data,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formRef.current.checkValidity()) {
      const combinedData = {
        ...formData.payrollDetails,
      };
      await handlePayrollDetailsApi(combinedData);
    } else {
      formRef.current.reportValidity();
    }
  };

  const handleEmployeeRegisterApi = async (data) => {
    try {
      const responseData = await AppEmployeeApi("POST", "addemployee", data);
      if (responseData.status === "Success") {
        console.log(responseData)
        setSuccessMessage(responseData.message);
        setUserId(responseData.data.user_id);
        setSuccessModal(true);
        console.log(successMessage)
      } else {
        setErrorMessage(responseData.message);
        setErrorModal(true);
        console.log(responseData)
      }
    } catch (error) {
      console.error("API call error for Step 1 & 2:", error);
      // setErrorMessage("Something is wrong please try again");
      setErrorModal(true);
    }
  };

  const handlePayrollDetailsApi = async (data) => {
    try {
      const responseData = await AddEmployeePayroll(
        "POST",
        "addemployeepayroll",
        data,
        userId
      );
      if (responseData.status_code >= 200 && responseData.status_code < 300) {
        console.log("Employee registered and payroll created successfully.");
        setSuccessModal(false); 
        closeForm();
        setSnackbarVisible(true); // Show snackbar on success
        // Hide the snackbar after 3 seconds
        setTimeout(() => {
          setSnackbarVisible(false);
        }, 3000);
      } else {
        setErrorMessage(responseData.message);
        setErrorModal(true);
      }
    } catch (error) {
      console.error("API call error for Step 1 & 2:", error);
    }
  };

  const prevStep = (e) => {
    e.preventDefault();
    setStep(step - 1);
  };

  return (
    <div className="relative w-4/5 h-full bg-white p-4 sm:p-0 rounded-lg overflow-y-auto scroll-container">
      <button
        onClick={closeForm} // Use the close function here
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
      >
        <IoClose size={24} />
      </button>

      <div className="p-6">
        <h1 className="text-xl font-semibold text-blue-900 mb-6">Onboard Employee</h1>
        <div className="flex flex-col md:flex-row items-start mb-8">
          <div className="md:w-auto flex flex-col items-center mb-6 md:mb-0 mr-8">
            <div className={`step ${step === 1 ? "active" : ""} flex flex-col items-center mb-6`}>
              <span className="step-number">1</span>
              <span className="step-label">
                Basic <br />
                Details
              </span>
            </div>
            <div className="h-16 w-1 bg-gray-300 relative my-2">
              <div className="absolute top-0 left-0 w-1 bg-blue-500 transition-all duration-300" style={{ height: `${step >= 2 ? 100 : 0}%` }} />
            </div>
            <div className={`step ${step === 2 ? "active" : ""} flex flex-col items-center mb-6`}>
              <span className="step-number">2</span>
              <span className="step-label">
                Employment <br />
                Details
              </span>
            </div>
            <div className="h-16 w-1 bg-gray-300 relative my-2">
              <div className="absolute top-0 left-0 w-1 bg-blue-500 transition-all duration-300" style={{ height: `${step >= 3 ? 100 : 0}%` }} />
            </div>
            <div className={`step ${step === 3 ? "active" : ""} flex flex-col items-center`}>
              <span className="step-number">3</span>
              <span className="step-label">
                Payroll <br />
                Details
              </span>
            </div>
          </div>

          <div className="md:w-10/12 w-full">
            <form ref={formRef}>
              {step === 1 && <BasicDetails formData={formData.basicDetails} onFormDataChange={(data) => handleFormDataChange("basicDetails", data)} />}
              {step === 2 && <EmploymentDetails formData={formData.employmentDetails} onFormDataChange={(data) => handleFormDataChange("employmentDetails", data)} />}
              {step === 3 && <EmployeePayrollDetails formData={formData.payrollDetails} onFormDataChange={(data) => handleFormDataChange("payrollDetails", data)} />}


              <div className={`flex ${step > 1 ? "justify-between" : "justify-end"} mt-6`}>
                {step > 1 && (
                  <button className="bg-gray-500 text-white px-4 py-2 rounded" onClick={prevStep}>
                    Previous
                  </button>
                )}
                {step < 3 ? (
                  <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={nextStep}>
                    Next
                  </button>
                ) : (
                  <button className="bg-green-500 text-white px-4 py-2 rounded" onClick={handleSubmit}>
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
        {errorModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Error!</h2>
              <p>{errorMessage}</p>
              <button onClick={() => setErrorModal(false)} className="mt-4 text-white bg-red-500 px-4 py-2 flex justify-center rounded hover:bg-red-600">
                Ok
              </button>
            </div>
          </div>
        )}
        {successModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto text-center">
              <h2 className="text-xl font-bold mb-4">Success!</h2>
              <p>Is the employee registered successfully?</p>
              <p className="mt-2">Are you going to fill the payroll details?</p>
              <div className="mt-4 flex justify-center space-x-4">
                <button onClick={() => { setSuccessModal(false); setStep(step + 1); }} className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-700 transition-colors">
                  Yes

                </button>
                <button onClick={() => { setSuccessModal(false); closeForm(); }} className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-700 transition-colors">
                  No
                </button>
              </div>
            </div>
          </div>
        )}

        {snackbarVisible && (
          <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white py-2 px-4 rounded-lg shadow-lg">
            Employee registered and payroll created successfully.
          </div>
        )}

      </div>
    </div>
  );
};

export default AddEmployee;
