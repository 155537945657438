import { Link } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useState, useEffect, useRef } from "react";
import { IoSearchOutline } from "react-icons/io5";



const ViewAttendance = () => {
  const [employees, setEmployees] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState(0);

  const dateInputRef = useRef(null);
  const daysPerPage = 16; // Number of days to show per page
  const attendanceThreshold = 1440; // Maximum threshold in minutes

  useEffect(() => {
    const mockEmployeeData = [
      {
        emp_id: "E001",
        name: "John Smith",
        attendance: Array.from({ length: 31 }, (_, i) => (i % 3 === 0 ? 1500 : 0))
      },
      {
        emp_id: "E002",
        name: "Maria Antonova",
        attendance: Array.from({ length: 31 }, (_, i) => (i % 3 === 0 ? 1300 : 0))
      },
      {
        emp_id: "E003",
        name: "David Branson",
        attendance: Array.from({ length: 31 }, (_, i) => (i % 4 === 0 ? 1500 : 0))
      },
      {
        emp_id: "E004",
        name: "Linda Johnson",
        attendance: Array.from({ length: 31 }, (_, i) => (i % 2 === 0 ? 1400 : 0))
      },
      {
        emp_id: "E005",
        name: "Michael Brown",
        attendance: Array.from({ length: 31 }, (_, i) => (i % 3 === 0 ? 1700 : 0))
      },
      {
        emp_id: "E006",
        name: "Patricia Taylor",
        attendance: Array.from({ length: 31 }, (_, i) => (i % 7 === 0 ? 1400 : 0))
      }


    ];
    setEmployees(mockEmployeeData);
  }, []);


  const handleStartDateChange = (e) => {
    const start = new Date(e.target.value);
    setStartDate(e.target.value);

    const end = new Date(start);
    end.setDate(end.getDate() + 30);

    const formattedEndDate = end.toISOString().split("T")[0];
    setEndDate(formattedEndDate);
    setCurrentPage(0);
  };

  const formatDate = (date) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const nextPage = () => {
    if (currentPage < Math.ceil(31 / daysPerPage) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getCurrentDays = () => {
    const start = currentPage * daysPerPage;
    const end = start + daysPerPage;
    return [...Array(31).keys()].slice(start, end);
  };

  const filteredEmployees = employees.filter((employee) => {
    const matchesQuery = searchQuery
      ? employee.name.toLowerCase().includes(searchQuery) ||
      employee.emp_id.toLowerCase().includes(searchQuery)
      : true;
    return matchesQuery;
  });

  const filteredAttendance = (attendance) => {
    switch (filter) {
      case "Attended":
        return attendance >= attendanceThreshold;
      case "Absent":
        return attendance === 0;
      case "Below Threshold":
        return attendance > 0 && attendance < attendanceThreshold;
      default:
        return true;
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen">
      <Sidebar />
      <div className="flex-1 overflow-y-auto">
        <div className="p-4 md:p-6">

          <div className="grid md:grid-cols-2  ">
            <div>
              <h1 className="text-2xl font-semibold text-gray-700 mb-4">Time and Attendance</h1>
            </div>

            <div className="md:ml-auto">

              {/* Date Range Selector Button */}
              <button
                onClick={() => document.getElementById("date-picker").click()} // Triggers the hidden input click
                className="flex items-center border border-green-500 rounded-full px-1 py-2  bg-green-50 shadow-lg"
              >
                <input
                  id="date-picker"
                  type="date"
                  ref={dateInputRef}
                  value={startDate}
                  onChange={handleStartDateChange} // Used here
                  className=" bg-green-50"
                  style={{ pointerEvents: "" }}
                />
                <span className="font-semibold text-gray-700">
                  {startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : ""}
                </span>
                {/* Hidden Date Input */}
              </button>
            </div>

            <hr />
            <hr />

            <div>
              <IoSearchOutline className="absolute top-[116px] right-[950px] text-slate-400" />
              <input
                type="text"
                placeholder="Search Employee"
                value={searchQuery}
                onChange={handleSearchChange}
                className=" p-2 border border-1 border-slate-300 bg-gray-100 rounded flex-1 md:w-80 mt-8 shadow-lg " />
              <span class="absolute inset-y-0 right-0 flex items-center pr-3 text-green-400 pointer-events-none">
              </span>
            </div>

            <button className=" py-2 h-fit px-6 border-2 text-green-500 border-green-500 rounded w-fit  mt-5 md:ml-auto shadow-lg">Export</button>
          </div>


          {/* Button Filters */}
          <div className="flex md:space-x-8 my-9 items-center text-center">
            <Link
              onClick={() => setFilter("All")}
              className={`px-4 py-2 text-lg rounded ${filter === "All" ? " text-black" : " text-blue-500"} transition duration-300 `} >
              All
            </Link>

            <div className="   ">
              <Link onClick={() => setFilter("Attended")}
                className={`px-2 py-2 rounded ${filter === "Attended" ? " text-green-600" : " text-gray-800"} transition duration-300`}>
                <span className="inline-block w-4 h-4 bg-green-300 rounded-full mr-2 "></span>
                <span className=" text-lg">Attended</span>
              </Link>
            </div>

            <div className="">
              <Link
                onClick={() => setFilter("Absent")}
                className={`px-2 py-2 text-lg rounded ${filter === "Absent" ? " text-red-700" : " text-gray-800"} transition duration-300`}>
                <span className="inline-block w-4 h-4 rounded-full  border-2 border-red-600 mr-2 "></span>
                Absent
              </Link>
            </div>

            <div className="">
              <Link
                onClick={() => setFilter("Below Threshold")}
                className={`px-2 py-2 text-lg rounded ${filter === "Below Threshold" ? " text-yellow-500" : " text-gray-800"} transition duration-300`}>
                <span className="inline-block w-4 h-4 rounded  border-2 border-yellow-400 mr-2"></span>
                Below Threshold
              </Link>
            </div>
          </div>

          <div className="mt-6 max-h-[400px] overflow-y-auto  rounded-lg w-full shadow-xl ">
            <table className="min-w-full bg-white border ">
              <thead className="bg-gray-100 text-gray-600 uppercase text-sm">
                <tr className=" ">
                  <th className="py-3 px-4 bg-gray-200  ">Employee Name
                    <button onClick={prevPage} className=" lg:absolute lg:right-[1050px]  px-3 lg:top-[265px] text-2xl font-semibold text-green-600  rounded-full">
                      &lt;
                    </button>
                  </th>

                  {getCurrentDays().map((day) => (
                    <th key={day} className="py-3 px-4 text-center bg-gray-200">
                      {day + 1}
                    </th>
                  ))}
                  {/* Pagination controls */}
                  <th className="py-3 px-4 text-center bg-gray-200 border-slate-300 ">

                    <button onClick={nextPage} className="px-3 lg:absolute lg:right-11 lg:top-[265px] text-2xl font-semibold text-green-600  rounded-full">
                      &gt;
                    </button>
                  </th>

                </tr>
              </thead>
              <tbody className="text-gray-700 text-sm">
                {filteredEmployees.map((employee) => (
                  <tr key={employee.emp_id} className="border">
                    <td className="py-3 px-6 font-medium sticky left-0 bg-white z-10 border">
                      {employee.name}
                    </td>
                    {getCurrentDays().map((day) => (
                      <td key={day} className="py-3 px-4 text-center border ">
                        {filteredAttendance(employee.attendance[day]) ? (
                          employee.attendance[day] >= attendanceThreshold ? (
                            <span className="inline-block w-5 h-5 bg-green-300 rounded-full"></span>
                          ) : employee.attendance[day] > 0 ? (
                            <span className="inline-block w-4 h-4 rounded  border-2 border-yellow-400"></span>
                          ) : (
                            <span className="inline-block w-4 h-4 rounded-full  border-2 border-red-600"></span>
                          )
                        ) : (
                          <span className="inline-block w-4 h-4 rounded-full bg-gray-300"></span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAttendance;
