import React from 'react';

const EmployeePayrollDetails = ({ formData = {}, onFormDataChange }) => {
  // Handle input change and update formData
  const handleChange = (e) => {
    const { name, value } = e.target;
    onFormDataChange({ ...formData, [name]: value });
  };

  return (
    <form className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Basic Salary */}
      <div>
        <label className="block text-gray-700">Basic Salary</label>
        <input
          type="number"
          name="basic"
          value={formData.basic || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"

          placeholder="basic salary"

        />
      </div>

      {/* Provident Fund */}
      <div>
        <label className="block text-gray-700">Provident Fund</label>
        <input
          type="number"
          name="pf"
          value={formData.pf || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"

          placeholder="provident fund"

        />
      </div>

      {/* Dearness Allowance (DA) */}
      <div>
        <label className="block text-gray-700">Dearness Allowance (DA)</label>
        <input
          type="number"
          name="da"
          value={formData.da || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"
          placeholder="Enter DA"
          required
        />
      </div>

      {/* House Rent Allowance (HRA) */}
      <div>
        <label className="block text-gray-700">House Rent Allowance (HRA)</label>
        <input
          type="number"
          name="hra"
          value={formData.hra || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"
          placeholder="Enter HRA"
          required
        />
      </div>

      {/* Conveyance Allowance (CA) */}
      <div>
        <label className="block text-gray-700">Conveyance Allowance (CA)</label>
        <input
          type="number"
          name="ca"
          value={formData.ca || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"
          placeholder="Enter CA"
          required
        />
      </div>

      {/* Gross Earning */}
      <div>
        <label className="block text-gray-700">Gross Earning</label>
        <input
          type="number"
          name="gross_earning"
          value={formData.gross_earning || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"

          placeholder="gross earning"

        />
      </div>

      {/* Professional Tax (PT) */}
      <div>
        <label className="block text-gray-700">Professional Tax (PT)</label>
        <input
          type="number"
          name="pt"
          value={formData.pt || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"
          placeholder="Enter PT"
          required
        />
      </div>

      {/* Employee State Insurance (ESI) */}
      <div>
        <label className="block text-gray-700">Employee State Insurance (ESI)</label>
        <input
          type="number"
          name="esi"
          value={formData.esi || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"
          placeholder="Enter ESI"
          required
        />
      </div>

      {/* Labour Welfare Fund (LWF) */}
      <div>
        <label className="block text-gray-700">Labour Welfare Fund (LWF)</label>
        <input
          type="number"
          name="lwf"
          value={formData.lwf || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"
          placeholder="Enter LWF"
          required
        />
      </div>

      {/* Gross Deduction */}
      <div>
        <label className="block text-gray-700">Gross Deduction</label>
        <input
          type="number"
          name="gross_deduction"
          value={formData.gross_deduction || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"

          placeholder="gross deduction"

        />
      </div>

      {/* Net Salary */}
      <div>
        <label className="block text-gray-700">Net Salary</label>
        <input
          type="number"
          name="net_salary"
          value={formData.net_salary || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"

          placeholder="net salary"

        />
      </div>
    </form>
  );
};

export default EmployeePayrollDetails;
