import React, { useState } from 'react';
import { FaEnvelope } from 'react-icons/fa'; // If you're using the email icon

const BasicDetails = ({ formData = {}, onFormDataChange }) => {
    const [firstName, setFirstName] = useState(formData.firstName || '');
    const [middleName, setMiddleName] = useState(formData.middleName || '');
    const [lastName, setLastName] = useState(formData.lastName || '');
    const [email, setEmail] = useState(formData.email || '');
    const [phone, setPhone] = useState(formData.phone || '');
    const [dob, setDob] = useState(formData.dob || '');
    const [gender, setGender] = useState(formData.gender || '');
    const [streetAddress, setStreetAddress] = useState(formData.streetAddress || '');
    const [city, setCity] = useState(formData.city || '');
    const [state, setState] = useState(formData.state || '');
    const [zipCode, setZipCode] = useState(formData.zipCode || '');
    const [nationalId, setNationalId] = useState(formData.nationalId || '');
    const [maritalStatus, setMaritalStatus] = useState(formData.maritalStatus || '');
    const [emergencyContactName, setEmergencyContactName] = useState(formData.emergencyContactName || '');
    const [emergencyContactRelationship, setEmergencyContactRelationship] = useState(formData.emergencyContactRelationship || '');
    const [emergencyContactPhone, setEmergencyContactPhone] = useState(formData.emergencyContactPhone || '');
    const [profilePicture, setProfilePicture] = useState(formData.profilePicture || '');
    const [password, setPassword] = useState(formData.password || '');

    const maxDate = (() => {
        const today = new Date();
        today.setFullYear(today.getFullYear() - 18);
        today.setDate(today.getDate() + 1); // Set to the day after
        return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    })();

    const handleFieldChange = (field, value) => {
        // console.log(profilePicture);
        const updatedData = {
            ...formData,
            [field]: value
        };
        onFormDataChange(updatedData);
        // console.log(profilePicture);
    };

    return (
        <div className="step-content grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* First Name */}
            <div>
                <label htmlFor="firstName" className="block text-gray-700">First Name <span className="text-red-500">*</span></label>
                <input
                    id="firstName"
                    type="text"
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        handleFieldChange('firstName', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="first name"
                    required
                />
            </div>

            {/* Middle Name */}
            <div>
                <label htmlFor="middleName" className="block text-gray-700">Middle Name</label>
                <input
                    id="middleName"
                    type="text"
                    value={middleName}
                    onChange={(e) => {
                        setMiddleName(e.target.value);
                        handleFieldChange('middleName', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="middle name"
                />
            </div>

            {/* Last Name */}
            <div>
                <label htmlFor="lastName" className="block text-gray-700">Last Name <span className="text-red-500">*</span></label>
                <input
                    id="lastName"
                    type="text"
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value);
                        handleFieldChange('lastName', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="last name"
                    required
                />
            </div>

            {/* Gender */}
            <div>
                <label htmlFor="gender" className="block text-gray-700">Gender <span className="text-red-500">*</span></label>
                <select
                    id="gender"
                    value={gender}
                    onChange={(e) => {
                        setGender(e.target.value);
                        handleFieldChange('gender', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1 text-gray-500"
                    required
                >
                    <option value="" disabled>Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                </select>
            </div>

            {/* Date of Birth */}
            <div>
                <label htmlFor="dob" className="block text-gray-700">Date of Birth <span className="text-red-500">*</span></label>
                <input
                    id="dob"
                    type="date"
                    value={dob}
                    max={maxDate}
                    onChange={(e) => {
                        setDob(e.target.value);
                        handleFieldChange('dob', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1 text-gray-600"
                    required
                />
            </div>

            {/* Email */}
            <div className="relative">
                <label htmlFor="email" className="block text-gray-700">Email </label>
                <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        handleFieldChange('email', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="email"
                />
                <FaEnvelope className="absolute right-3 top-10 text-gray-400" />
            </div>

            {/* Phone */}
            <div>
                <label htmlFor="phone" className="block text-gray-700">Contact Number <span className="text-red-500">*</span></label>
                <input
                    id="phone"
                    type="tel" // Change to 'tel' for better phone input handling
                    value={phone}
                    maxLength={10}
                    minLength={10}
                    onChange={(e) => {
                        const value = e.target.value;
                        // Allow only digits and limit to 10 characters
                        if (/^\d*$/.test(value) && value.length <= 10) {
                            setPhone(value);
                            handleFieldChange('phone', value);
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="contact number"
                    required
                />
            </div>


            {/* ZIP Code */}
            <div>
                <label htmlFor="zipCode" className="block text-gray-700">ZIP Code <span className="text-red-500">*</span></label>
                <input
                    id="zipCode"
                    type="text" // Change to 'text' to handle leading zeros
                    value={zipCode}
                    maxLength={6}
                    minLength={6}
                    onChange={(e) => {
                        const value = e.target.value;

                        // Allow only digits and limit to 6 characters
                        if (/^\d*$/.test(value) && value.length <= 6) {
                            setZipCode(value);
                            handleFieldChange('zipCode', value);
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="ZIP code"
                    required
                />
            </div>


            {/* Address */}
            <div>
                <label htmlFor="streetAddress" className="block text-gray-700">Street/LandMark<span className="text-red-500">*</span></label>
                <input
                    id="streetAddress"
                    type="text"
                    value={streetAddress}
                    onChange={(e) => {
                        setStreetAddress(e.target.value);
                        handleFieldChange('streetAddress', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="street"
                    required
                />
            </div>

            {/* City */}
            <div>
                <label htmlFor="city" className="block text-gray-700">City <span className="text-red-500">*</span></label>
                <input
                    id="city"
                    type="text"
                    value={city}
                    onChange={(e) => {
                        setCity(e.target.value);
                        handleFieldChange('city', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="city"
                    required
                />
            </div>

            {/* State */}
            <div>
                <label htmlFor="state" className="block text-gray-700">State <span className="text-red-500">*</span></label>
                <input
                    id="state"
                    type="text"
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value);
                        handleFieldChange('state', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="state"
                    required
                />
            </div>



            {/* National ID */}
            <div>
                <label htmlFor="nationalId" className="block text-gray-700">Aadhar Number<span className="text-red-500">*</span></label>
                <input
                    id="nationalId"
                    type="number"
                    value={nationalId}
                    maxLength={12}
                    minLength={12}
                    onChange={(e) => {
                        const value = e.target.value;
                        // Allow only digits and limit to 12 characters
                        if (/^\d*$/.test(value) && value.length <= 12) {
                            setNationalId(value);
                            handleFieldChange('nationalId', value);
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="aadhar number"
                    required
                />
            </div>

            {/* Marital Status */}
            <div>
                <label htmlFor="maritalStatus" className="block text-gray-700">Marital Status<span className="text-red-500">*</span></label>
                <select
                    id="maritalStatus"
                    value={maritalStatus}
                    onChange={(e) => {
                        setMaritalStatus(e.target.value);
                        handleFieldChange('maritalStatus', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1 text-gray-500 "
                    required
                >
                    <option value="" disabled className=''>Select marital status</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                </select>
            </div>

             {/* Profile Picture */}
             <div>
                <label htmlFor="profilePicture" className="block text-gray-700">Profile Picture<span className="text-red-500">*</span></label>
                <input
                    id="profilePicture"
                    type="file"
                    onChange={(e) => {
                        const file = e.target.files[0];
                        setProfilePicture(file);
                        handleFieldChange('profilePicture', file);
                        console.log(profilePicture)
                    }}  
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    required
                />
            </div>


            <div className='col-span-2'>
                <h2 className='text-lg font-semibold text-green-500'>Emergency Contact :</h2>
            </div>

            {/* Emergency Contact Name */}
            <div>
                <label htmlFor="emergencyContactName" className="block text-gray-700"> Name<span className="text-red-500">*</span></label>
                <input
                    id="emergencyContactName"
                    type="text"
                    value={emergencyContactName}
                    onChange={(e) => {
                        setEmergencyContactName(e.target.value);
                        handleFieldChange('emergencyContactName', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="name"
                    required
                />
            </div>

            {/* Emergency Contact Relationship */}
            <div>
                <label htmlFor="emergencyContactRelationship" className="block text-gray-700"> Relationship<span className="text-red-500">*</span></label>
                <select
                    id="emergencyContactRelationship"
                    type="text"
                    value={emergencyContactRelationship}
                    onChange={(e) => {
                        setEmergencyContactRelationship(e.target.value);
                        handleFieldChange('emergencyContactRelationship', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1 text-gray-500"
                    placeholder="relationship"
                    required>
                    <option value="" disabled >Choose RelationShip</option>
                    <option value="Wife">Wife</option>
                    <option value="Husband">Husband</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Son">Son</option>
                    <option value="Daughter">Daughter</option>
                    <option value="Friend">Friend</option>
                </select>
            </div>

            {/* Emergency Contact Phone */}
            <div>
                <label htmlFor="emergencyContactPhone" className="block text-gray-700">number<span className="text-red-500">*</span></label>
                <input
                    id="emergencyContactPhone"
                    type="text"
                    value={emergencyContactPhone}
                    maxLength={10}
                    minLength={10}
                    onChange={(e) => {
                        const value = e.target.value;
                        // Allow only digits and limit to 10 characters
                        if (/^\d*$/.test(value) && value.length <= 10) {
                            setEmergencyContactPhone(value);
                            handleFieldChange('emergencyContactPhone', value);
                        }
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder=" number"
                    required
                />
            </div>

           

            {/* Password */}
            <div>
                <label htmlFor="password" className="block text-gray-700">Password<span className="text-red-500">*</span></label>
                <input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        handleFieldChange('password', e.target.value);
                    }}
                    className="w-full px-4 py-2 border border-gray-300 rounded mt-1"
                    placeholder="password"
                    required
                />
            </div>
        </div>
    );
};

export default BasicDetails;
