import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_API_URL;
//const API_BASE_URL = `https://api2.neohrms.in`;


export const API_URLS = {
  login: `${API_BASE_URL}/tenant/login`,
  addemployee: `${API_BASE_URL}/user/register`,
  roles: `${API_BASE_URL}/role/all`,
  addemployeepayroll: `${API_BASE_URL}/payroll/create`,
  getallemployee: `${API_BASE_URL}/user/all`,
  leaverequestcreate: `${API_BASE_URL}/leave/create`,
  getleaverequests: `${API_BASE_URL}/leave/get`
};

export const LoginApi = async (method, endpoint, formData, hostHeader) => {
  // const url = `https://api.neohrms.in/tenant/login`;
  const url = API_URLS[endpoint];


  console.log(url);
  try {
    const response = await axios({
      method: method,
      url: url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "X-Tenant": hostHeader,
      },
      data: method === "POST" ? formData.toString() : null,
      withCredentials: true, // Required to send/receive cookies
    });
    return response;
  } catch (error) {
    if (error.response) {
      console.log("Error data:", error.response.data);
      return error.response.data;
    } else {
      console.log("Fetch error:", error.message);
      throw new Error("An error occurred");
    }
  }
};

export const AppEmployeeApi = async (method, endpoint, data) => {
  const url = API_URLS[endpoint];

  const payload = {
    user_id: data.employeeId,
    department_id: data.department,
    role_ids: data.role,
    first_name: data.firstName,
    middle_name: data.middleName,
    last_name: data.lastName,
    email: data.email,
    phone: data.phone,
    password: data.password,
    job_title: data.jobTitle,
    date_of_joining: data.dateOfJoining,
    date_of_birth: data.dob,
    gender: data.gender,
    address: {
      street: data.streetAddress,
      city: data.city,
      state: data.state,
      zip: data.zipCode,
    },
    uid: data.nationalId,
    marital_status: data.maritalStatus,
    emergency_contact: {
      name: data.emergencyContactName,
      relationship: data.emergencyContactRelationship,
      phone: data.emergencyContactPhone,
    },
    employment_status: data.employmentStatus,
    job_type: data.contractType,
    contractor_name: data.contractorName,
    bank_account_details: {
      account_number: data.bankaccountnumber,
      bank_name: data.bankaccountname,
    },
    work_location: data.workLocation,
    work_shift: data.workShift,
    biometrics_data: {
      fingerprint: "base64_encoded_fingerprint",
    },
    resignation_date: null,
    termination_reason: data.terminationReason,
  };
  try {
    console.log(payload); // Logging the payload for debugging
    const sessionToken = Cookies.get("session_token");
    console.log(sessionToken);
    const response = await axios({
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: method === "POST" ? JSON.stringify(payload) : null,
      withCredentials: true, // This ensures that cookies are sent/received
    });

    // // Check if the response is successful
    // if (response.data.status_code >= 200 && response.data.status_code < 300) {
    //     return response;  // Axios automatically parses JSON responses
    // } else {
    //     console.log("Error data:", response);
    //     return response;
    // }
    console.log(response.data);
    return response.data;
  } catch (error) {
    // Handle fetch errors
    if (error.response) {
      console.log("Error data:", error.response.data);
      return error.response.data;
    } else {
      console.log("Fetch error:", error.message);
    }
    throw new Error("An error occurred");
  }
};

export const GetRolesApi = async (endpoint) => {
  const url = API_URLS[endpoint];

  try {
    const response = await axios.get(url, {
      withCredentials: true,
    });
    console.log("Response data:", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.log("Error data:", error.response.data);
      throw new Error("Error fetching data");
    } else {
      console.log("Fetch error:", error.message);
      throw new Error("An error occurred");
    }
  }
};

export const AddEmployeePayroll = async (method, endpoint, data, userId) => {
  const url = API_URLS[endpoint];

  const payload = {
    user_id: userId,
    basic: data.basic,
    da: data.da,
    hra: data.hra,
    ca: data.ca,
    gross_earning: data.gross_earning,
    pf: data.pf,
    pt: data.pt,
    esi: data.esi,
    lwf: data.lwf,
    gross_deduction: data.gross_deduction,
    net_salary: data.net_salary,
  };
  try {
    console.log(payload); // Logging the payload for debugging
    const response = await axios({
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: method === "POST" ? JSON.stringify(payload) : null,
      withCredentials: true, // This ensures that cookies are sent/received
    });
    return response.data;
  } catch (error) {
    // Handle fetch errors
    if (error.response) {
      console.log("Error data:", error.response.data);
      return error.response.data;
    } else {
      console.log("Fetch error:", error.message);
    }
    throw new Error("An error occurred");
  }
};

export const GetAllEmployee = async (endpoint) => {
  const url = API_URLS[endpoint];

  try {
    const response = await axios.get(url, {
      withCredentials: true,
    });
    console.log("Response data:", response.data);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.log("Error data:", error.response.data);
      throw new Error("Error fetching data");
    } else {
      console.log("Fetch error:", error.message);
      throw new Error("An error occurred");
    }
  }
};



export const GetLeaveRequests = async (endpoint) => {
  const url = API_URLS[endpoint];

  try {
    const response = await axios.get(url, {
      withCredentials: true,
    });
    console.log("Response data:", response.data);
    return response;
  } catch (error) {
    if (error.response) {
      console.log("Error data:", error.response.data);
      throw new Error("Error fetching data");
    } else {
      console.log("Fetch error:", error.message);
      throw new Error("An error occurred");
    }
  }
};



export const LeaveRequestCreate = async (method, endpoint, data) => {
  const url = API_URLS[endpoint];

  const payload = {
    "user_id": data.user_id,
    "leave_type": data.leaveType,
    "start_date": "2024-10-30",
    "end_date": "2024-11-01",
    "approval_status": "pending",
    "reason": data.reason
  };
  try {
    console.log(payload); // Logging the payload for debugging
    const response = await axios({
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: method === "POST" ? JSON.stringify(payload) : null,
      withCredentials: true, // This ensures that cookies are sent/received
    });
    return response.data;
  } catch (error) {
    // Handle fetch errors
    if (error.response) {
      console.log("Error data:", error.response.data);
      return error.response.data;
    } else {
      console.log("Fetch error:", error.message);
    }
    throw new Error("An error occurred");
  }
};


