import React from 'react';
import { FiBell, FiSettings} from 'react-icons/fi'; // Import icons from react-icons

const Navbar = ({ pageTitle }) => {
  return (
    <nav className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-6">
        <div className="flex justify-between items-center h-16">
          {/* Left: Page Title*/}
          <div className="flex items-center">
            {/* Page Title */}
            <span className="text-xl font-semibold text-blue-900">{pageTitle}</span>
          </div>

          {/* Right: Profile, Settings, Notifications */}
          <div className="flex items-center space-x-4">
            <span className="text-lg text-blue-900">Rajas Techie</span>
            
            {/* Notification Icon */}
            <button className="p-1 text-gray-400 hover:text-gray-600">
              <FiBell className="h-6 w-6" />
              <span className="sr-only">Notifications</span>
            </button>

            {/* Settings Icon */}
            <button className="p-1 text-gray-400 hover:text-gray-600">
              <FiSettings className="h-6 w-6" />
              <span className="sr-only">Settings</span>
            </button>

            {/* Profile Picture */}
            <div className="relative">
              <img
                className="h-8 w-8 rounded-full object-cover"
                src="https://via.placeholder.com/40"
                alt="User Avatar"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
